// MUI
// import Typography from "@mui/material/Typography";
// import Paper from "@mui/material/Paper";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "next/link";
import Image from "next/image";

// Images
import DownpipesGuide from "../public/images/DownpipesGuide.webp";
import BMWDownpipesGuide from "../public/images/BMWDownpipesGuide.webp";
import CatalyticConvertersGuide from "../public/images/catalyticConvertersGuide.webp";
import AftermarketExhaustTotalGuide from "../public/images/aftermarketExhaustTotalGuide.webp";

// Styles
const body = {
  bgcolor: "background.secondary",
  paddingTop: "2vh",
  paddingBottom: "5vh",
  textAlign: "center",
};

const h2Titles = {
  color: "text.secondary",
  fontSize: "2.5rem",
};

const imageHeight = 329
const SuggestBlogPostsHorizontal = (props) => {

  return (
    <Box sx={body}>
      {/* <Typography variant="h2" sx={h2Titles}>
        Featured Posts
      </Typography> */}
      <Grid container spacing={2} direction="row" alignItems="center" sx={{position: 'relative', mt: "1vh", mb: "1vh" }}>
        <Grid item sx={{position: 'relative', minHeight: "30vh", height: "50%", width: "100%", overflow: "hidden"}} md={3} xs={6}>
          <Link style={{ textDecoration: "none", color: "unset" }} href="https://searchexhaust.com/blog/ultimate-downpipes-guide-2022" passHref prefetch={false}>
            <Image style={{ objectFit: "contain" }}  alt="Downpipes guide blogpost" fill src={DownpipesGuide} quality={100} unoptimized />
          </Link>
        </Grid>
        <Grid item sx={{position: 'relative', height: "50%", minHeight: "30vh", overflow: "hidden"}} md={3} xs={6}>
          <Link style={{ textDecoration: "none", color: "unset" }} href="https://searchexhaust.com/blog/best-downpipes-for-BMW-M2C-M3-M4-s55" passHref prefetch={false}>
            <Image alt="best bmw downpipes" style={{ objectFit: "contain" }} fill src={BMWDownpipesGuide} quality={100} unoptimized/>
          </Link>
        </Grid>
        <Grid item  sx={{position: 'relative', height: "50%", minHeight: "30vh", overflow: "hidden"}} md={3} xs={6}>
          <Link style={{ textDecoration: "none", color: "unset" }} href="https://searchexhaust.com/blog/what-are-catalytic-converters-how-they-work-and-why-are-they-stolen-so-often" passHref prefetch={false}>
            <Image alt="catalytic converters" style={{ objectFit: "contain" }} fill src={CatalyticConvertersGuide} quality={100}  unoptimized/>
          </Link>
        </Grid>
        <Grid item sx={{position: 'relative', height: "50%", minHeight: "30vh", overflow: "hidden"}} md={3} xs={6}>
          <Link style={{ textDecoration: "none", color: "unset" }} href="https://searchexhaust.com/blog/guide-to-aftermarket-exhaust-systems" passHref prefetch={false}>
            <Image alt="AftermarketExhaustGuide" style={{ objectFit: "contain" }} fill src={AftermarketExhaustTotalGuide} quality={100} unoptimized/>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuggestBlogPostsHorizontal;
